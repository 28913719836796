<template>
	<PageLayout :title="$t('wallets.create-wallet')" :subtitle="$t('wallets.create-wallet-subtitle')" medium-width>
		<div class="container">
			<Spacer height size="xxl" />
			<MaterialSelect
				id="account"
				v-model="form.isDemo.value"
				:error="form.isDemo.error"
				:label="$t('wallets.account')"
			>
				<option v-for="(option, index) in form.isDemo.options" :key="index" :value="option.value">
					{{ option.label }}
				</option>
			</MaterialSelect>
			<MaterialSelect
				id="currency"
				v-model="form.currency.value"
				:error="form.currency.error"
				:label="$t('wallets.currency')"
			>
				<option v-for="(option, index) in form.currency.options" :key="index" :value="option.value">
					{{ option.label }}
				</option>
			</MaterialSelect>
			<MaterialInput
				v-model="form.leverage.value"
				:error="form.leverage.error"
				:label="$t('wallets.leverage')"
				type="number"
				class="pb-0"
			/>
			<p class="description">
				{{ $t('wallets.leverage-info') }}
			</p>
			<div v-if="error" class="error-box">
				<img width="14" :src="require('../../assets/icons/error.png')" />
				<p class="error-info">{{ error }}</p>
			</div>
			<CButton primary full-width :loading="loading" @click="createWallet()">
				{{ $t('actions.create') }}
			</CButton>
		</div>
	</PageLayout>
</template>

<script>
import PageLayout from '@/components/page-layout/PageLayout';
import MaterialSelect from '@/components/material-select/MaterialSelect';
import MaterialInput from '@/components/material-input/MaterialInput';
import CButton from '@/shared/cbutton/CButton';
import Spacer from '@/shared/spacer/Spacer.vue';
import apiClient from '../../api';
import { extractErrorMessage, extractValidationErrorMessage } from '@/utils/errorHandling';

export default {
	name: 'CreateWallet',
	components: {
		PageLayout,
		MaterialSelect,
		MaterialInput,
		CButton,
		Spacer,
	},
	data() {
		return {
			loading: false,
			error: null,
			form: {
				isDemo: {
					options: [
						{ label: 'Live', value: 'false' },
						{ label: 'Demo', value: 'true' },
					],
					value: false,
					error: null,
				},
				currency: {
					options: [{ label: 'USD', value: 'USD' }],
					value: 'USD',
					error: null,
				},
				leverage: {
					value: null,
					error: null,
				},
			},
		};
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
		async createWallet() {
			this.loading = true;
			this.clearErrors();
			const { isDemo, currency, leverage } = this.form;

			try {
				await apiClient.createWallet(currency.value, leverage.value, this.formValueToBoolean(isDemo.value));
				this.navigateTo('/wallet-created');
			} catch (e) {
				this.handleErrors(e);
			} finally {
				this.loading = false;
			}
		},
		handleErrors(e) {
			if (e?.response?.status === 422) {
				const errors = extractValidationErrorMessage(e);

				if (errors) {
					for (const error of errors) {
						const { param, msg } = error;
						if (this.form[param]) {
							this.form[param].error = msg;
						}
					}
				}
			} else {
				this.error = extractErrorMessage(e);
			}
		},
		clearErrors() {
			this.error = null;
			this.form.isDemo.error = null;
			this.form.currency.error = null;
			this.form.leverage.error = null;
		},
		formValueToBoolean(val) {
			const strToBoolMap = {
				true: true,
				false: false,
			};
			return strToBoolMap[val];
		},
	},
};
</script>
<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.container {
	@include card;
}

.header {
	display: flex;
	align-items: center;
	justify-content: center;
}

.description {
	font-size: $caption;
	color: rgba(255, 255, 255, 0.5);
	font-style: italic;
	text-align: left;
}

.error-box {
	background: $shark-black;
	border-radius: 5px;
	padding: $spacer-l;
	display: flex;
	align-items: flex-start;
	gap: $spacer-xs;
	margin-bottom: $spacer-xl;
}

.error-info {
	color: $error-pink;
	font-size: $caption;
	text-align: left;
	margin: 0px;
}

.pb-0 {
	padding-bottom: 0px !important;
}
</style>
